@import "@angular/cdk/overlay-prebuilt.css";
@import "ngx-toastr/toastr";
@import 'bootstrap/scss/bootstrap';
/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    font-family: "Saira Semi Condensed", sans-serif;

    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 50px;
        background: #1f1f21;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: #676868;
    }
}

:root {
    // Body
    --body-background-color: blue;

    // Button
    --app-btn-primary-background: linear-gradient(0deg, rgba(115, 5, 35, 1) 0%, rgba(204, 36, 27, 1) 100%);
    --app-btn-success-background: linear-gradient(0deg, rgba(96, 168, 30, 1) 0%, rgba(139, 213, 30, 1) 100%);

    // Spinner
    --app-spinner-color: #fff;

    // Input
    --app-input-background: #1f1f21;
    --app-input-color: #fff;

    // Checkbox
    --app-checkbox-background: linear-gradient(0deg, rgba(96, 168, 30, 1) 0%, rgba(139, 213, 30, 1) 100%);
    --app-checkbox-border-color: rgba(139, 213, 30, 1);
    --app-checkbox-hover-background: rgba(255, 255, 255, 0.1);

    // Select
    --app-select-background: #1f1f21;
    --app-select-color: #fff;
    --app-select-content-background: #1f1f21;
    --app-select-content-color: #fff;

    // Init loader

    --init-loader-panel-background: #1f1f1f;

    // --app-main-wrapper-header-background: #1f1f1f;
    --app-main-wrapper-content-background: #242424;

    --app-main-menu-background: #1f1f1f;
    --app-filters-menu-background: #1f1f1f;

    --app-tournament-dialog-register-accent-text-color: #60a81e;

    --cropper-outline-color: rgba(0, 0, 0, 0.8);
    --cropper-overlay-color: rgba(0, 0, 0);
}

body {
}

.main-background {
    // background: var(--body-background-color);

    // HandsetLandscape
    @media (max-width: 959.98px) and (orientation: landscape) {
        background-size: cover;

        background-color: #111; // Iphone Safari Landscape left and right side background color
    }

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: -1;
    background-size: cover;

    &.dark {
        filter: grayscale(1) brightness(0.75);
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

// ngx-toastr

.toast-title {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
}

.side-menu {
    height: 100%;
    max-width: 300px;

    overflow-y: auto;
}

.cdk-dialog-container {
    &:focus {
        outline: none;
    }
}

.dialog {
    display: flex;
    flex-direction: column;
    background: #222121;
    color: #fff;
    border-radius: 8px;
    padding: 10px;
    max-width: 500px;

    &-header {
        position: relative;
        margin-bottom: 15px;
        padding: 5px 5px 0 5px;

        &-title {
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }

        &-close-button {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;

            button {
                border: none;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.25);

                &:focus {
                    outline: none;
                }
            }

            .dialog-header-close-button-icon {
                fill: rgba(255, 255, 255, 0.9);
                height: 16px;
                display: flex;
            }
        }
    }

    &-content {
        max-height: 500px;
        overflow: auto;
        padding: 5px;
    }

    &-footer {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;

        &-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 70%;
        }
    }
}

/*
XSmall	(max-width: 599.98px)
Small	(min-width: 600px) and (max-width: 959.98px)
Medium	(min-width: 960px) and (max-width: 1279.98px)
Large	(min-width: 1280px) and (max-width: 1919.98px)
XLarge	(min-width: 1920px)
Handset	(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)
Tablet	(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)
Web	(min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)
🟢 HandsetPortrait	(max-width: 599.98px) and (orientation: portrait)
TabletPortrait	(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)
WebPortrait	(min-width: 840px) and (orientation: portrait)
🟢 HandsetLandscape	(max-width: 959.98px) and (orientation: landscape)
TabletLandscape	(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)
WebLandscape	(min-width: 1280px) and (orientation: landscape)

*/

/* Importing Bootstrap SCSS file. */

